import React, { useState } from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as XLSX from "xlsx";
import {
    faArrowLeft,
    faArrowRight,
    faCheckCircle, faDownload,
    faFileExcel,
    faPlus,
    faTrash
} from "@fortawesome/free-solid-svg-icons";

function Invite3({ prev, next }) {
    const { formData, updateFormData } = useFormContext();
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const validationSchema = Yup.object({
        regions: Yup.array().of(
            Yup.object({
                regionName: Yup.string().required('Region Name is required'),
                numBrokers: Yup.number()
                    .required('Number of Brokers is required')
                    .min(1, 'Must have at least one broker')
                    .typeError('Must be a number'),
            })
        ),
    });

    const handleExcelFileUpload = (file, setValues, values) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const binaryString = e.target.result;
            const workbook = XLSX.read(binaryString, { type: "binary" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];

            // Convert worksheet to JSON
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            const parsedData = [];
            let currentRegion = null;
            let currentBroker = null;

            jsonData.forEach((row, index) => {
                if (index === 0 || !row[0]) return; // Skip the header row or empty rows

                const [
                    regionName,
                    brokerName,
                    brokerageName,
                    brokerEmail,
                    numAssociates,
                    agentName,
                    agentEmail,
                ] = row;

                if (regionName) {
                    currentRegion = {
                        regionName: regionName.trim(),
                        numBrokers: 0,
                        brokers: [],
                    };
                    parsedData.push(currentRegion);
                }

                if (brokerName && currentRegion) {
                    currentBroker = {
                        brokerName: brokerName.trim(),
                        brokerageName: brokerageName ? brokerageName.trim() : '',
                        email: brokerEmail ? brokerEmail.trim() : '',
                        numAssociates: numAssociates ? parseInt(numAssociates, 20) : 0,
                        agents: [],
                    };
                    currentRegion.brokers.push(currentBroker);
                    currentRegion.numBrokers += 1;
                }

                if (agentName && agentEmail && currentBroker) {
                    currentBroker.agents.push({
                        agentName: agentName.trim(),
                        agentEmail: agentEmail.trim(),
                    });
                }
            });

            // Update the form data dynamically
            const updatedValues = {
                ...values,
                regions: parsedData,
            };

            setValues(updatedValues); // Update Formik values
            updateFormData({ ...formData, regions: updatedValues.regions }); // Update global context
        };

        reader.readAsBinaryString(file);
    };

    return (
        <div className="container mt-5 text-start">
            <h2>Set Up Regions and Brokers</h2>
            <p className="text-start">
                Start by offering regions and the number of Brokerages in the region. Brokerages will then be asked to set up Brokers, Teams Leaders, and Team Associates/Agents.
            </p>
            <Formik
                initialValues={{
                    regions: formData.regions || [{ regionName: '', numBrokers: '' }],
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    updateFormData({ ...formData, ...values });
                    setSuccessMessage("Regions and broker details saved successfully!");
                    setOpenSuccessModal(true);
                }}
            >
                {({ values, setValues }) => (
                    <Form className="w-100">
                        <div className="row text-start">
                            <div className="col-md-6">
                                <FieldArray name="regions">
                                    {({push, remove}) => (
                                        <>
                                    {values.regions.map((region, index) => (
                                        <div key={index} className="p-2 ">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group text-start">
                                                        <label htmlFor={`regions.${index}.regionName`}>
                                                            Region Name
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            className="form-control"
                                                            id={`regions.${index}.regionName`}
                                                            name={`regions.${index}.regionName`}
                                                            placeholder="Enter region name"
                                                        />
                                                        <ErrorMessage
                                                            name={`regions.${index}.regionName`}
                                                            component="div"
                                                            className="text-danger"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor={`regions.${index}.numBrokers`}>
                                                            Number of Brokerages
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <Field
                                                            type="number"
                                                            className="form-control"
                                                            id={`regions.${index}.numBrokers`}
                                                            name={`regions.${index}.numBrokers`}
                                                            placeholder="Enter number of brokers"
                                                        />
                                                        <ErrorMessage
                                                            name={`regions.${index}.numBrokers`}
                                                            component="div"
                                                            className="text-danger"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-md-2 d-flex justify-content-center align-items-center">
                                                    <Button
                                                        type="button"
                                                        variant="outlined"
                                                        color="error"
                                                        onClick={() => remove(index)}
                                                        startIcon={<FontAwesomeIcon icon={faTrash}/>}
                                                    >
                                                    </Button>
                                                </div>
                                            </div>
                                            <hr/>
                                        </div>
                                    ))}
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            onClick={() => push({regionName: '', numBrokers: ''})}
                                            startIcon={<FontAwesomeIcon icon={faPlus}/>}
                                        >
                                            Add Region
                                        </Button>
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                            <div className="col-md-6" style={{
                                border: '2px dotted #ccc',
                                height: '100%',
                            }}>
                                <div className="card p-4 text-start">
                                    <h4>Submit an Excel File</h4>
                                    <p>Rather than fill in the boxes you have the option to upload an Excel/CSV file. Make it four (4) columns; Region Name, Brokerage Name, Broker's Name, and email address. Upload and an invitation request will be sent out to each broker to add Brokers, Team Names, Name of Team Leaders, Email, Associate/Agent, and email. </p>
                                    <div className="form-group my-2">
                                        <div
                                            className="file-upload-box d-flex flex-column align-items-center justify-content-center"
                                            onClick={() => document.getElementById('fileInput').click()}
                                            style={{
                                                border: '2px dashed #ccc',
                                                padding: '20px',
                                                cursor: 'pointer',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faFileExcel} size="2x" color="red"/>
                                            <p className="mt-3">Upload Excel/CSV File</p>
                                        </div>


                                        <input
                                            hidden
                                            type="file"
                                            id="fileInput"
                                            accept=".xlsx, .xls"
                                            onChange={(e) => handleExcelFileUpload(e.target.files[0], setValues, values)}
                                        />
                                        <small className="form-text text-muted">
                                            File Format requires a header for each column: Region Name, Brokerage Name, Broker's Name, and Email Address. Download Sample Excel, Submit button.
                                        </small>
                                        <br/>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="me-2"
                                            title="Download and fill the sample file and upload it."
                                            startIcon={<FontAwesomeIcon icon={faDownload}/>}
                                            component="a"
                                            href="sample-yst-residencies.xlsx"
                                            download="sample-yst-residencies.xlsx">
                                            Download Sample Excel
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button  btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}
                            >
                                Prev
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight}/>}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
            <Dialog
                open={openSuccessModal}
                onClose={() => setOpenSuccessModal(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <div className="d-flex justify-content-center">
                        <FontAwesomeIcon icon={faCheckCircle} size="3x" color="green"/>
                    </div>
                    <p className="text-center mt-3">{successMessage}</p>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        onClick={next}
                        className="btn-danger"
                        endIcon={<FontAwesomeIcon icon={faArrowRight}/>}
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Invite3;
