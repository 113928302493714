import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight, faPlus, faRemove, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';

const SalesStaffForm = ({ prev, next }) => {
    const { formData, updateFormData } = useFormContext();

    useEffect(() => {
        if ((!formData.salesTeams || formData.salesTeams.length === 0) && formData.numSalesStaff > 0) {
            const initialStaff = Array.from({ length: formData.numSalesStaff }, () => ({
                locationName: '',
                contactName: '',
                contactEmail: '',
            }));
            updateFormData({ salesTeams: initialStaff });
        }
    }, [formData.numSalesStaff, formData.salesTeams, updateFormData]);

    // Validation Schema for marketing staff
    const validationSchema = Yup.object().shape({
        salesTeams: Yup.array()
            .of(
                Yup.object().shape({
                    locationName: Yup.string().required('Region/Group Name is required'),
                    contactName: Yup.string().required('Contact Name is required'),
                    contactEmail: Yup.string()
                        .email('Invalid email address')
                        .required('Contact Email is required'),
                })
            )
            .min(1, 'At least one marketing staff is required'),
    });

    // Add a new row for marketing staff
    const handleAddStaff = (values, setFieldValue) => {
        const newStaff = { locationName: '', contactName: '', contactEmail: '' };
        setFieldValue('salesTeams', [...values.salesTeams, newStaff]);
    };

    // Remove a specific row
    const handleRemoveStaff = (index, values, setFieldValue) => {
        const updatedStaff = values.salesTeams.filter((_, i) => i !== index);
        setFieldValue('salesTeams', updatedStaff);
    };

    // Clear all staff rows
    const handleClearStaff = (resetForm) => {
        resetForm({ values: { salesTeams: [] } });
        updateFormData({ salesTeams: [], numSalesStaff: 0 });
    };

    return (
        <div className="container mt-5 mb-5 text-start fade-in">
            <h2>
                {formData.industryType === "Senior Real Estate"
                    ? "Invite Your Broker, Team Leaders"
                    : "Invite Your Regional or Group Sales Leaders"}
            </h2>
            <p>Fill out the details for each marketing staff member below.</p>
            <Formik
                initialValues={formData}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                    updateFormData(values);
                    next();
                }}
            >
                {({ values, handleChange, handleBlur, setFieldValue, resetForm }) => (
                    <Form>
                        <div>
                            {values.salesTeams && values.salesTeams.length > 0 && (
                                <div className="labels-row mb-2 row">
                                    <div className="col-md-3"><strong>Region/Group Name</strong></div>
                                    <div className="col-md-3"><strong>Contact Name</strong></div>
                                    <div className="col-md-3"><strong>Contact Email</strong></div>
                                    <div className="col-md-1"><strong>Action</strong></div>
                                </div>
                            )}

                            {values.salesTeams.map((staff, index) => (
                                <div key={index} className="row align-items-center mb-3">
                                    <div className="col-md-3">
                                        <Field
                                            name={`salesTeams[${index}].locationName`}
                                            className="form-control"
                                            placeholder="Region/Group Name"
                                        />
                                        <ErrorMessage
                                            name={`salesTeams[${index}].locationName`}
                                            component="div"
                                            className="text-danger mt-2"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <Field
                                            name={`salesTeams[${index}].contactName`}
                                            className="form-control"
                                            placeholder="Contact Name"
                                        />
                                        <ErrorMessage
                                            name={`salesTeams[${index}].contactName`}
                                            component="div"
                                            className="text-danger mt-2"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <Field
                                            name={`salesTeams[${index}].contactEmail`}
                                            className="form-control"
                                            placeholder="Contact Email"
                                        />
                                        <ErrorMessage
                                            name={`salesTeams[${index}].contactEmail`}
                                            component="div"
                                            className="text-danger mt-2"
                                        />
                                    </div>
                                    <div className="col-md-1">
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={() => handleRemoveStaff(index, values, setFieldValue)}
                                            startIcon={<FontAwesomeIcon icon={faTimes}/>}
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                </div>
                            ))}

                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleAddStaff(values, setFieldValue)}
                                startIcon={<FontAwesomeIcon icon={faPlus}/>}
                            >
                                Add Staff
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleClearStaff(resetForm)}
                                className="ms-2"
                                startIcon={<FontAwesomeIcon icon={faTrash}/>}
                            >
                                Clear All
                            </Button>
                        </div>
                        <hr style={{margin: '10px 0'}}/>
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}
                            >
                                Prev
                            </Button>
                            <Button
                                type="button"
                                onClick={next}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faRemove} />}>
                                Skip
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight} className="icon"/>}>
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SalesStaffForm;
