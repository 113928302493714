import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowRight,
    faDownload,
    faFileExcel,
    faCheckCircle,
    faUpload, faRemove
} from "@fortawesome/free-solid-svg-icons";

function InviteSalesStaff({ prev, next }) {
    const { formData, updateFormData } = useFormContext();

    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");


    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.name.endsWith('.xlsx')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const binaryString = e.target.result;
                    const workbook = XLSX.read(binaryString, { type: 'binary' });
                    const worksheet = workbook.Sheets['Sheet1'];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet);

                    const salesTeamsData = jsonData.map(row => ({
                        locationName: row['Location Name'] || '',
                        contactName: row['Name of the Contact'] || '',
                        contactEmail: row['Contact Email Address'] || '',
                    }));

                    updateFormData({
                        ...formData,
                        salesTeams: salesTeamsData
                    });

                    setSuccessMessage("File uploaded successfully!");
                    setOpenSuccessModal(true);
                };
                reader.readAsBinaryString(file);
            } else if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
                Papa.parse(file, {
                    complete: (result) => {
                        const salesTeamsData = result.data.map(row => ({
                            locationName: row['Location Name'] || '',
                            contactName: row['Name of the Contact'] || '',
                            contactEmail: row['Contact Email Address'] || '',
                        }));

                        updateFormData({
                            ...formData,
                            salesTeams: salesTeamsData
                        });

                        setSuccessMessage("File uploaded successfully!");
                        setOpenSuccessModal(true);
                    },
                    header: true,
                });
            }
        }
    };

    return (
        <div className="container mt-5">
            <div className="header">
                <h2 className="text-start">
                    {formData.industryType === "Senior Real Estate"
                        ? "Invite Your Broker Team Leader , Broker Associate or Agent "
                        : "Invite Your Regional Sales or Group Sales Professionals"}
                </h2>
                <p className="text-start">Upon submitting their information, an invitation is automatically sent to each one.
                    <br/>
                    They are able to set up their region or group of sales professionals they oversee and set invites directly to
                    their group.
                    <br/>
                    The person then sets up their local accounts for seniors to access, and you and your leaders can access them to oversee their activity on Your Senior Team.
                </p>
            </div>

            <Formik
                initialValues={{
                    numSalesStaff: formData.numSalesStaff || '',
                    csvFile: formData.csvFile || null,
                }}
                onSubmit={(values) => {
                    updateFormData({ ...formData, ...values });
                    next();
                }}
            >
                {({ setFieldValue }) => (
                    <Form className="w-100">
                        <div className="row">

                            <div className="col-md-6" style={{
                                border: '2px dotted #ccc',
                                height: '100%',
                            }}>
                                <div className="card p-4 text-start mt-5">
                                    <h4>{formData.industryType === "Senior Real Estate"
                                        ? "Enter the Number of Broker, Team Leader , Broker Associate or Agent"
                                        : "Enter the Number of Regionals or Group Leaders to Invite: #"}</h4>
                                    <p>A form will be created to fill in or cut and paste each name, email address, and local office or firm name.</p>
                                    <div className="form-group">
                                        <label htmlFor="numSalesStaff">
                                            {formData.industryType === "Senior Real Estate"
                                                ? "Number of Broker, Team Leader , Broker Associate or Agent "
                                                : "Number of Regionals or Group Leaders"}
                                            <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                            type="number"
                                            className="form-control"
                                            id="numSalesStaff"
                                            name="numSalesStaff"
                                            placeholder="Number of Regionals or Group Leaders"
                                        />
                                        <ErrorMessage name="numSalesStaff" component="div" className="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{
                                border: '2px dotted #ccc',
                                height: '100%',
                            }}>
                                <div className="card p-4 text-start">
                                    <h4>Submit an Excel File</h4>
                                    <p>Please set up your Excel/CSV file in four columns, including first name, last name, email address, and name of region, office, branch, or firm, and upload it to invite all those you provide. </p>
                                        <div className="form-group my-2">
                                        <div
                                            className="file-upload-box d-flex flex-column align-items-center justify-content-center"
                                            onClick={() => document.getElementById('fileInput').click()}
                                            style={{
                                                border: '2px dashed #ccc',
                                                padding: '20px',
                                                cursor: 'pointer',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faFileExcel} size="2x" color="red"/>
                                            <p className="mt-3">Upload Excel/CSV File</p>
                                        </div>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            accept=".csv, .xlsx"
                                            onChange={handleFileUpload}
                                            hidden
                                        />
                                        <small className="form-text text-muted">
                                            File Format: Ensure Column Headers are titled: Team Name, Name of the
                                            Contact, Contact Email Address, Address of Team Location
                                        </small>
                                            <br/>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="me-2"
                                                title="Download and fill the sample file and upload it."
                                                startIcon={<FontAwesomeIcon icon={faDownload}/>}
                                                component="a"
                                                href="sample-yst-residencies.xlsx"
                                                download="sample-yst-residencies.xlsx">
                                                Download Sample Excel
                                            </Button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <hr className="mt-2"/>
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}
                            >
                                Prev
                            </Button>
                            <Button
                                type="button"
                                onClick={next}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faRemove} />}>
                                Skip
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight} className="icon"/>}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>

            <Dialog
                open={openSuccessModal}
                onClose={() => setOpenSuccessModal(false)}
                sx={{
                    "& .MuiDialog-paper": {
                        width: '80%',
                        maxWidth: '600px',
                    }
                }}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                <div className="d-flex justify-content-center">
                        <FontAwesomeIcon icon={faCheckCircle} size="3x" color="green" />
                    </div>
                    <p className="text-center mt-3">{successMessage}</p>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        onClick={next}
                        className="next-button btn-danger"
                        endIcon={<FontAwesomeIcon icon={faArrowRight} className="icon" />}>
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default InviteSalesStaff;
