
import { faArrowRight, faHome , faPerson , faHouseUser ,
    faBank,faMoneyBill, faIndustry, faBuilding,
    faUsers , faBusinessTime } from '@fortawesome/free-solid-svg-icons';

export const entityTypes = [
    { value: 'corporation', label: 'Corporation' },
    { value: 'company', label: 'Company' },
    { value: 'llc', label: 'LLC' },
    { value: 'partnership', label: 'Partnership' },
    { value: 'sole-proprietor', label: 'Sole Proprietor' },
    { value: 'non-profit', label: 'Non Profit' },
    { value: 'Team', label: 'Team' },
    { value: 'other', label: 'Other' },
];

export const userTypes = [
    { value: 'corporate_representative', label: 'Corporate Representative' },
    { value: 'company_representative', label: 'Company Representative' },
    { value: 'regional_representative', label: 'Regional Representative' },
    { value: 'owner', label: 'Owner' },
    { value: 'partner', label: 'Partner' },
    { value: 'administrator', label: 'Administrator' },
    { value: 'sales_and_marketing_representative_or_executive', label: 'Sales & Marketing Representative / Executive' },
];
export const offerings = [
    { value: 'Communities', label: 'Communities With Private Units or Apartments' },
    { value: 'single_family', label: 'Single Family Residence with Bedrooms' },
    { value: 'other' , label: 'Other' },
];


export const marketingStaffArray = {
    default : [
        { value: 'regional_sales_director_manager', label: 'Administrator,Managers,Partners,Marketing Directors,Dealers' },
        { value: 'marketing_sales_executive' , label: 'Agents, Sales Executives, Sales Representative, Sales Associate, Account Executive, Account Manager, Brokers, Regionals, Specialist or Advisor' },
    ],
    "Senior Real Estate": [
        { value: 'regional_sales_director_manager', label: 'Brokers' },
        { value: 'agent' , label: 'Broker Associates or agents' },
        { value: 'marketing_sales_executive' , label: 'Team Leaders' },
        { value: 'team_members' , label: 'Team Members' },
    ],
};

export const industryTypes = [
    {
        title: "Senior Living",
        description: "Assisted Living, Independent 55+, Memory Care, Residential Board and Care for Elderly (RCFE), CCRC (Continuing Care Retirement Community)",
        categories: [
            { name: "Independent 55+", value: "independent_55_plus" },
            { name: "Assisted Living", value: "assisted_living" },
            { name: "Memory Care", value: "memory_care" },
            { name: "Residential Board and Care for Elderly (RCFE)", value: "residential_board_and_care_for_elderly" },
            { name: "CCRC (Continuing Care Retirement Community)", value: "ccrc_continuing_care_retirement_community" },
        ],
        icon: faPerson,
    },
    {
        title: "Senior Real Estate",
        description: "Specializing in selling, buying, and renting properties for seniors",
        categories: [
            { name: "Specializing in selling", value: "specializing_in_selling" },
            { name: "Buying", value: "buying"  },
            { name: "Renting properties for seniors", value: "renting_properties_for_seniors" },
        ],
        icon: faBuilding,
    },
    {
        title: "Elder Law",
        description: "Trusts & Wills, Power of Attorney, Probate, Estate Planning, Conservatorship, Medicare, Disability, Elder Abuse, Litigation",
        categories: [
            { name: "Trusts & Wills", value: "trusts_and_wills" },
            { name: "Power of Attorney", value: "power_of_attorney" },
            { name: "Probate", value: "probate" },
            { name: "Estate Planning", value: " estate_planning" },
            { name: "Conservatorship", value: "conservatorship" },
            { name: "Medicare", value: "medicare" },
            { name: "Disability", value: "disability" },
            { name: "Elder Abuse", value: "elder_abuse" },
            { name: "Litigation", value: "litigation" },
        ],
        icon: faUsers,
    },
    {
        title: "Veterans Affairs",
        description: "Veteran Service Officer advocating for senior veterans",
        categories: [
            { name: "Veteran Service Officer advocating for senior veterans", value: "veteran_service_officer_advocating_for_senior_veterans" },
        ],
        icon: faHome,
    },
    {
        title: "Investment, Finance & Lending",
        description: "Stocks, Bonds, Mutual Funds, Life Settlement, Annuities, REIT, Certified Financial Planner, Retirement Planner, Reverse Mortgage, Bridge Loan, Home Equity Loan, Line of Credit, Home Mortgage",
        categories: [
            { name: "Stocks, Bonds, Mutual Funds", value: "stocks_bonds_mutual_funds" },
            { name: "Life Settlement", value: "life_settlement" },
            { name: "Annuities", value: "annuities" },
            { name: "REIT (Real Estate Investment Trust)", value: "reit_real_estate_investment_trust" },
            { name: "Certified Financial Planner", value: "certified_financial_planner" },
            { name: "Retirement Planner", value: "retirement_planner" },
            { name: "Reverse Mortgage", value: "reverse_mortgage" },
            { name: "Bridge Loan", value: "bridge_loan" },
            { name: "Home Equity Loan", value: "home_equity_loan" },
            { name: "Line of Credit", value: "line_of_credit" },
            { name: "Home Mortgage", value: "home_mortgage" },
        ],
        icon: faBusinessTime,
    },
    {
        title: "Estate Sales, Relocation, Packers & Movers",
        description: "Estate Sales, Vehicle Sales, Auction, Relocate, Organize, Downsize, Packer, Non-Profit, Consignment, Garage Sales, Mover",
        categories: [
            { name: "Estate Sales", value: "estate_sales" },
            { name: "Vehicle Sales", value: "vehicle_sales" },
            { name: "Auction", value: "auction" },
            { name: "Relocate", value: "relocate" },
            { name: "Organize", value: "organize" },
            { name: "Downsize", value: "downsize" },
            { name: "Packer", value: "packer" },
            { name: "Non-Profit", value: "non_profit" },
            { name: "Consignment", value: "consignment" },
            { name: "Garage Sales", value: "garage_sales" },
            { name: "Mover", value: "mover" },
        ],
        icon: faMoneyBill,
    },
    {
        title: "Insurance",
        description: "Medicare, Healthcare, Long Term Care, Life Insurance, Funeral Insurance, Home Owner Insurance, Liability Insurance",
        categories: [
            { name: "Medicare", value: "medicare" },
            { name: "Healthcare", value: "healthcare" },
            { name: "Long Term Care", value: "long_term_care" },
            { name: "Life Insurance", value: "life_insurance" },
            { name: "Funeral Insurance", value: "funeral_insurance" },
            { name: "Home Owner Insurance", value: "home_owner_insurance" },
            { name: "Liability Insurance", value: "liability_insurance" },
        ],
        icon: faBank,
    },
    {
        title: "Other",
        description: "Add your product or service for seniors",
        categories: [],
        icon: faIndustry,
    },
];
