import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowRight,
    faDownload,
    faFileExcel,
    faCheckCircle,
    faRemove
} from "@fortawesome/free-solid-svg-icons";

function InviteRegionalStaff({ prev, next }) {
    const { formData, updateFormData } = useFormContext();

    const [openSuccessModal, setOpenSuccessModal] = useState(false); // To control modal visibility
    const [successMessage, setSuccessMessage] = useState("");

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.name.endsWith('.xlsx')) {
                // Handle XLSX file
                const reader = new FileReader();
                reader.onload = (e) => {
                    const binaryString = e.target.result;
                    const workbook = XLSX.read(binaryString, { type: 'binary' });
                    const worksheet = workbook.Sheets['Sheet1'];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet);

                    const childCompaniesData = jsonData.map(row => ({
                        locationName: row['Location Name'] || '',
                        contactName: row['Name of the Contact'] || '',
                        contactEmail: row['Contact Email Address'] || '',
                        locationAddress: row['Address of Location'] || ''
                    }));

                    updateFormData({
                        ...formData,
                        childCompanies: childCompaniesData
                    });

                    // Show success modal
                    setSuccessMessage("File uploaded successfully!");
                    setOpenSuccessModal(true);
                };
                reader.readAsBinaryString(file);
            } else if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
                // Handle CSV file with PapaParse
                Papa.parse(file, {
                    complete: (result) => {
                        const childCompaniesData = result.data.map(row => ({
                            locationName: row['Branch Name'] || '',
                            contactName: row['Name of the Contact'] || '',
                            contactEmail: row['Contact Email Address'] || '',
                            locationAddress: row['Name of Location'] || ''
                        }));

                        updateFormData({
                            ...formData,
                            childCompanies: childCompaniesData
                        });

                        // Show success modal
                        setSuccessMessage("File uploaded successfully!");
                        setOpenSuccessModal(true);
                    },
                    header: true,
                });
            }
        }
    };

    return (
        <div className="container mt-5">
            <div className="header">
                <h2 className="text-start">
                    {formData.industryType === "Senior Real Estate"
                        ? "Invite Your Team Leaders"
                        : "Invite Your Local, Office, Branch, Firm Sales Executives, Specialists,\n" +
                        "                    Advisors,<br/> or Attorneys under your oversight"}
                    </h2>
                <p className="text-start">Upon submitting their information, invitations will be sent to them to complete their profiles, to establish their presence in the senior market and give seniors direct access to them.
                <br/>
                    {formData.industryType === "Senior Real Estate"
                        ? " Add Number of Team Leaders to send an invitation. You can send to 1 or 500 by submitting this form or uploading an Excel/CVS File "
                        : " Add Number of Sales Executives or Attorney or Advisors to send an invitation. You can send to 1 or 500 by submitting this form or uploading an Excel/CVS File"}
                </p>
            </div>

            <Formik
                initialValues={{
                    numRepresentatives: formData.numRepresentatives || '',
                    csvFile: formData.csvFile || null,
                }}

                onSubmit={(values) => {
                    updateFormData({ ...formData, ...values }); // Store form data in context
                    next(); // Proceed to the next step
                }}
            >
                {({ setFieldValue }) => (
                    <Form className="w-100">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card p-4 text-start">
                                    <h4>Add Number of
                                        {formData.industryType === "Senior Real Estate"
                                            ? "Team Leaders"
                                            : "Representatives"}

                                        </h4>
                                    <p>Number to Invite</p>
                                    <div className="form-group">
                                        <label htmlFor="numRepresentatives">
                                            Number to Invite<span className="text-danger">*</span>
                                        </label>
                                        <Field
                                            type="number"
                                            className="form-control"
                                            id="numRepresentatives"
                                            name="numRepresentatives"
                                            placeholder="Number to Invite"
                                        />
                                        <ErrorMessage name="numRepresentatives" component="div" className="text-danger" />
                                   <br/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card p-4 text-start">
                                    <h4>Submit a Excel File</h4>
                                    <p>Fill in the columns with the proper header format and fill in the invite.
                                        Name of Region, Group or Office, First Name of Contact, Last Name of Contact, Email Address
                                    </p>
                                    <div className="form-group">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component="label"
                                            startIcon={<FontAwesomeIcon icon={faFileExcel}/>}
                                            className="me-2">
                                            Upload Your Excel/CVS File
                                            <input
                                                type="file"
                                                accept=".csv, .xlsx"
                                                onChange={handleFileUpload}
                                                hidden
                                            />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="dark"
                                            className="me-2"
                                            title="Download and fill the sample file and upload it."
                                            startIcon={<FontAwesomeIcon icon={faDownload}/>}
                                            component="a"
                                            href="sample-yst-residencies.xlsx"
                                            download="sample.xlsx">
                                            See an Example for an Excel/CVS File
                                        </Button>
                                        <ErrorMessage name="csvFile" component="div" className="text-danger"/>
                                        <br/>
                                        <small className="form-text text-muted">
                                            Invitations will be sent to each of them to set up their profiles. The Your Senior Team marketplace will provide seniors and their adult children access to information, services, and direct contact with them.
                                            <br/>
                                            File Format: Make Sure Column Headers are Titled : Location Name ,
                                            Name of the Contact, Contact Email Address , Address of Location
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="mt-2" />
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                            >
                                Prev
                            </Button>
                            <Button
                                type="button"
                                onClick={next}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faRemove} />}>
                                Skip
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight} className="icon" />}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>


            <Dialog
                open={openSuccessModal}
                onClose={() => setOpenSuccessModal(false)}
                sx={{
                    "& .MuiDialog-paper": {
                        width: '80%', // Adjust width as needed
                        maxWidth: '600px', // Max width for larger screens
                    }
                }}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <div className="d-flex justify-content-center">
                        <FontAwesomeIcon icon={faCheckCircle} size="3x" color="green" />
                    </div>
                    <p className="text-center mt-3">{successMessage}</p>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        onClick={next}
                        className="next-button btn-danger"
                        endIcon={<FontAwesomeIcon icon={faArrowRight} className="icon" />}>
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default InviteRegionalStaff;
