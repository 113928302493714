import React, { useState, useEffect, useCallback } from 'react';
import Register from './Register';
import EntityType from './EntityType';
import CorporateForm from "./CorporateForm";
import CompanyLogo from "./CompanyLogo";
import UserType from "./UserType";
import IndustryType from "./IndustryType";
import { useFormContext } from '../context/FormContext';
import WhatWeOffer from "./WhatWeOffer";
import MarketingStaff from "./MarketingStaff";
import InviteRegionalStaff from "./InviteRegionalStaff";
import RegionalStaffForm from "./RegionalStaffForm";
import InviteSalesStaff from "./InviteSalesStaff";
import SalesStaffForm from "./SalesStaffForm";
import ReviewAndSubmit from "./ReviewAndSubmit";
import Invite3 from "./Invite3";
import Invite4 from "./Invite4";
import Invite5 from "./Invite5";
import Invite7 from "./Invite7";
import Invite8 from "./Invite8";

const YstForm = () => {
    const { formData } = useFormContext();
    const [step, setStep] = useState(1);
    const [skipSteps, setSkipSteps] = useState([]);

    useEffect(() => {
        const newSkipSteps = [];

        if (formData.selectedCompany) {
            newSkipSteps.push(6 ,7);
        }
        if (
            formData.userType === "sales_and_marketing_representative_or_executive" || (formData.industryType !== "Senior Living" && formData.industryType !== "Senior Real Estate")
        ) {
            newSkipSteps.push(8, 9, 10, 11, 12);
        }
        if (!formData.marketingStaff?.includes("regional_sales_director_manager")) {
            newSkipSteps.push(10, 11); // Skip steps 8 and 9 if not included
        }

        // Check for `marketing_sales_executive` in marketingStaff
        if (!formData.marketingStaff?.includes("marketing_sales_executive")) {
            newSkipSteps.push(11, 12); // Skip steps 10 and 11 if not included
        }

        if (formData.industryType === "Senior Real Estate") {
             newSkipSteps.push( 9, 10, 11, 12);

            if (!formData.marketingStaff?.includes("regional_sales_director_manager")) {
                newSkipSteps.push(13, 14, 15);
            }
            if (!formData.marketingStaff?.includes("marketing_sales_executive")) {
                newSkipSteps.push(16 ,17);
            }
            if (!formData.marketingStaff?.includes("team_members")) {
                newSkipSteps.push(17);
            }
             if (!formData.marketingStaff?.includes("agent")) {
                  newSkipSteps.push(15);
             }

        }

        setSkipSteps(newSkipSteps);
    }, [formData.selectedCompany,formData.userType, formData.marketingStaff, formData.industryType]); // Ensure all dependencies are included

    useEffect(() => {
        localStorage.setItem('currentStep', step);
    }, [step]);

    const next = useCallback(() => {
        setStep((prevStep) => {
            let newStep = prevStep + 1;
            while (skipSteps.includes(newStep)) {
                newStep += 1;
            }
            return newStep;
        });
    }, [skipSteps]);

    const prev = useCallback(() => {
        setStep((prevStep) => {
            let newStep = prevStep - 1;
            while (skipSteps.includes(newStep)) {
                newStep -= 1;
            }
            return Math.max(newStep, 1);
        });
    }, [skipSteps]);

    return (
        <div>

            {step === 1 && <IndustryType next={next} />}
            {step === 2 && <Register prev={prev} next={next} />}
            {step === 3 && <EntityType prev={prev} next={next} />}
            {step === 4 && <UserType prev={prev} next={next} />}
            {step === 5 && !skipSteps.includes(5) && <CorporateForm prev={prev} next={next} />}
            {step === 6 && !skipSteps.includes(6) && <CompanyLogo prev={prev} next={next} />}
            {step === 7 && !skipSteps.includes(7) && <WhatWeOffer prev={prev} next={next} />}
            {step === 8 && !skipSteps.includes(8) && <MarketingStaff prev={prev} next={next} />}
            {step === 9 && !skipSteps.includes(9) && <InviteSalesStaff prev={prev} next={next} />}
            {step === 10 && !skipSteps.includes(10) && <SalesStaffForm prev={prev} next={next} />}
            {step === 11 && !skipSteps.includes(11) && <InviteRegionalStaff prev={prev} next={next} />}
            {step === 12 && !skipSteps.includes(12) && <RegionalStaffForm prev={prev} next={next} />}
            {step === 13 && !skipSteps.includes(13) && <Invite3 prev={prev}  next={next} />}
            {step === 14 && !skipSteps.includes(14) && <Invite4 prev={prev} next={next} />}
            {step === 15 && !skipSteps.includes(15) && <Invite5 prev={prev} next={next} />}
            {step === 16 && !skipSteps.includes(16) && <Invite7 prev={prev} next={next} /> }
            {step === 17 && !skipSteps.includes(17) && <Invite8 prev={prev} next={next} /> }
            {step === 18 && <ReviewAndSubmit />}
        </div>
    );
};

export default YstForm;
