import React from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useFormContext } from "../context/FormContext";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Invite5({ prev, next }) {
    const { formData, updateFormData } = useFormContext();

    const initialValues = {
        regions: formData.regions.map((region) => ({
            regionName: region.regionName,
            numBrokers: region.numBrokers,
            brokers: region.brokers && region.brokers.length > 0
                ? region.brokers.map((broker) => ({
                    brokerName: broker.brokerName || '',
                    brokerageName: broker.brokerageName || '',
                    email: broker.email || '',
                    numAssociates: broker.numAssociates || 0, // Ensure numAssociates is set
                    numTeams: broker.numTeams || 0,
                    // Initialize agents based on numAssociates
                    agents: broker.numAssociates && broker.numAssociates > 0
                        ? Array.from({ length: parseInt(broker.numAssociates, 10) }).map((_, index) => ({
                            agentName: broker.agents && broker.agents[index] ? broker.agents[index].agentName : '',
                            agentEmail: broker.agents && broker.agents[index] ? broker.agents[index].agentEmail : '',
                        }))
                        : [],
                    teams: broker.teams && broker.teams.length > 0
                        ? broker.teams.map((team) => ({
                            teamName: team.teamName || '',
                            teamLeaderName: team.teamLeaderName || '',
                            email: team.email || '',
                            numAssociates: team.numAssociates || '',
                            teamMembers: team.teamMembers && team.teamMembers.length > 0
                                ? team.teamMembers.map((member) => ({
                                    memberName: member.memberName || '',
                                    memberEmail: member.memberEmail || '',
                                }))
                                : (team.numAssociates > 0
                                    ? Array.from({ length: parseInt(team.numAssociates, 10) }).map(() => ({
                                        memberName: '',
                                        memberEmail: '',
                                    }))
                                    : []),
                        }))
                        : broker.numTeams > 0
                            ? Array.from({ length: parseInt(broker.numTeams, 10) }).map(() => ({
                                teamName: '',
                                teamLeaderName: '',
                                email: '',
                                numAssociates: '',
                            }))
                            : [],
                }))
                : [],
        })),
    };


    const validationSchema = Yup.object({
        regions: Yup.array().of(
            Yup.object({
                regionName: Yup.string(),
                brokers: Yup.array().of(
                    Yup.object({
                        brokerName: Yup.string(),
                        agents: Yup.array().of(
                            Yup.object({
                                agentName: Yup.string().required("Agent name is required"),
                                agentEmail: Yup.string()
                                    .email("Invalid email format")
                                    .required("Agent email is required"),
                            })
                        ),
                    })
                ),
            })
        ),
    });

    return (
        <div className="container mt-5 text-start">
            <h2>Assign & Invite Associates/Agents</h2>
            <p className="text-start">
                Please provide the name and contact for each Associate/Agent
            </p>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    updateFormData({ ...formData, ...values });
                    next();
                }}
            >
                {({ values }) => (
                    <Form className="w-100">
                        {values.regions.map((region, regionIndex) => (
                            <div key={regionIndex} className="card p-3 mb-4">
                                <h4>Region: {region.regionName}</h4>
                                {region.brokers.map((broker, brokerIndex) => (
                                    <div key={brokerIndex} className="card mb-3 p-3 bg-light">
                                        <h5>Broker: {broker.brokerName}</h5>
                                        <FieldArray name={`regions.${regionIndex}.brokers.${brokerIndex}.agents`}>
                                            {({ push, remove }) => (
                                                <>
                                                    {broker.agents.map((agent, agentIndex) => (
                                                        <div key={agentIndex} className="row mb-3">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor={`regions.${regionIndex}.brokers.${brokerIndex}.agents.${agentIndex}.agentName`}
                                                                    >
                                                                        Agent/Associate Name
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        id={`regions.${regionIndex}.brokers.${brokerIndex}.agents.${agentIndex}.agentName`}
                                                                        name={`regions.${regionIndex}.brokers.${brokerIndex}.agents.${agentIndex}.agentName`}
                                                                        placeholder="Enter agent name"
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`regions.${regionIndex}.brokers.${brokerIndex}.agents.${agentIndex}.agentName`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor={`regions.${regionIndex}.brokers.${brokerIndex}.agents.${agentIndex}.agentEmail`}
                                                                    >
                                                                        Agent Email
                                                                    </label>
                                                                    <Field
                                                                        type="email"
                                                                        className="form-control"
                                                                        id={`regions.${regionIndex}.brokers.${brokerIndex}.agents.${agentIndex}.agentEmail`}
                                                                        name={`regions.${regionIndex}.brokers.${brokerIndex}.agents.${agentIndex}.agentEmail`}
                                                                        placeholder="Enter agent email"
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`regions.${regionIndex}.brokers.${brokerIndex}.agents.${agentIndex}.agentEmail`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <Button
                                                        type="button"
                                                        onClick={() => push({ agentName: "", agentEmail: "" })}
                                                        className="btn btn-primary mb-3 text-start"
                                                    >
                                                        Add Agent
                                                    </Button>
                                                </>
                                            )}
                                        </FieldArray>
                                    </div>
                                ))}
                            </div>
                        ))}
                        <hr />
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                            >
                                Prev
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default Invite5;
