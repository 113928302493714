import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight, faPlus, faRemove, faTrash} from '@fortawesome/free-solid-svg-icons';
import ChildCompanyForm from './sections/ChildCompanyForm';
import '../styles.css';

const RegionalStaffForm = ({ prev, next }) => {
    const { formData, updateFormData } = useFormContext();


    useEffect(() => {
        if ((!formData.childCompanies || formData.childCompanies.length === 0) && formData.numRepresentatives > 0) {
            const initialChildCompanies = Array.from({ length: formData.numRepresentatives }, () => ({
                locationName: '',
                contactName: '',
                contactEmail: ''
            }));
            updateFormData({ childCompanies: initialChildCompanies });
        }
    }, [formData.childCompanies, formData.numRepresentatives, updateFormData]);


    const validationSchema = Yup.object({
        childCompanies: Yup.array().of(
            Yup.object({
                locationName: Yup.string().required('Child company name is required'),
                contactName: Yup.string().required('Contact full name is required'),
                contactEmail: Yup.string().required('Contact email is required').email('Invalid email format'),
            })
        ),
    });

    // Handle adding a new child company
    const handleAddChildCompany = () => {
        updateFormData({
            childCompanies: [...(formData.childCompanies || []), {
                locationName: '',
                contactName: '',
                contactEmail: ''
            }],
        });
    };

    // Handle removing a child company
    const handleRemoveChildCompany = (index) => {
        const updatedChildCompanies = formData.childCompanies.filter((_, i) => i !== index);
        updateFormData({ childCompanies: updatedChildCompanies });
    };

    // Handle clearing all child companies and resetting the form
    const handleClearChildCompanies = (resetForm) => {
        updateFormData({ childCompanies: [], numRepresentatives: 0 }); // Clear the form data
        resetForm(); // Reset the entire form to initial state
    };

    return (
        <div className="container mt-5 mb-5 text-start fade-in">
            <div className="header">
                <h2>Send Your Invite to Your Sales, Broker, Dealer, or Attorney who conducts local marketing and sales and interacts directly with seniors and their families. </h2>
                <p>We will send them your invitation so they can set up their profiles and connect directly with you and prospective referrals and clients in their area. </p>
            </div>
            <Formik
                initialValues={formData}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    updateFormData(values);
                    next();
                }}>
                {({ handleChange, handleBlur, values, resetForm }) => (
                    <Form className="text-start">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="child-companies-section">
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleClearChildCompanies(resetForm)}
                                        className="me-2"
                                        startIcon={<FontAwesomeIcon icon={faTrash} />}
                                    >
                                        Remove All Branches
                                    </Button>
                                    {formData.childCompanies && formData.childCompanies.length > 0 && (
                                        <div className="labels-row mb-2 row mt-5">
                                            <div className="label-item col-md-3"><strong>Local Office, Branch, Firm Name</strong> <span className="text-danger">*</span></div>
                                            <div className="label-item col-md-2"><strong>Contact Full Name</strong><span className="text-danger">*</span></div>
                                            <div className="label-item col-md-3"><strong>Contact Email</strong><span className="text-danger">*</span></div>
                                            <div className="label-item col-md-1"><strong>Action</strong> <span className="text-danger">*</span></div>
                                        </div>
                                    )}
                                    {formData.childCompanies?.map((company, index) => (
                                        <div key={index} className="child-company">
                                            <ChildCompanyForm
                                                index={index}
                                                company={company}
                                                onRemove={() => handleRemoveChildCompany(index)}
                                            />
                                        </div>
                                    ))}
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className="me-2"
                                        onClick={handleAddChildCompany}>
                                        <FontAwesomeIcon icon={faPlus} /> +Add Another Region or Group
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <hr style={{ margin: '10px 0' }} />
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                            >
                                Prev
                            </Button>
                            <Button
                                type="button"
                                onClick={next}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faRemove} />}>
                                Skip
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight} className="icon" />}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default RegionalStaffForm;
